import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CheckInService extends Service {
    @service ajax;
    @service session;
    @service notificationManager;

    @tracked isSendCheckpointIdVerificationDisabled = false;

    /**
     * Check the prospect in. If geofencing is enabled, perform a location scan. If CPID is enabled and the prospect
     * needs to scan, transition to CPID.
     */
    @action async appointmentCheckIn(property, appointment) {
        if (property.geofenceEnabled) {
            await this.validateLocation(appointment);
        }

        if (appointment.get('activePendingIdVerification')) {
            await this.sendCheckpointIdVerification(
                appointment.get('id'),
                false
            );
        }
    }

    /**
     * Validate prospect's coordinates.
     */
    @action async validateLocation(appointment) {
        try {
            const options = {
                enableHighAccuracy: false,
                timeout: 60000,
                maximumAge: 0
            };
            await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        try {
                            await this.compareLocation(position, appointment);
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    },
                    async () => {
                        reject(this.locationShareError);
                    },
                    options
                );
            });
        } catch (response) {
            this.notificationManager.addError(response);
            return this.ajax.handleAjaxError(response, 'Unable to check-in.');
        }
    }

    /**
     * Takes in position and appointment arguments and makes a request to determine if the
     * prospect is within the geofence.
     */
    @action async compareLocation(position, appointment) {
        const loadedAppointment = await appointment,
            appointmentId = parseInt(loadedAppointment.id),
            latitude = position.coords.latitude,
            longitude = position.coords.longitude;

        const requestUrl = '/appointments/checkin',
            newOptions = this.getAuthHeaders(),
            body = JSON.stringify({
                appointmentId,
                latitude,
                longitude
            }),
            httpMethod = 'POST';

        try {
            await this.ajax.apiRequest(
                requestUrl,
                newOptions,
                body,
                httpMethod
            );
        } catch (error) {
            const errorMessage = error.errors[0].detail;
            return Promise.reject(errorMessage);
        }
    }

    /**
     * Error message if prospect chooses to not share location.
     */
    locationShareError =
        'Please enable location services on your device and share your location in the browser in order to check-in.';

    /**
     * Gets necessary authorization for header.
     */
    getAuthHeaders() {
        const { token } = this.session.data.authenticated;
        return {
            headers: {
                authorization: token
            }
        };
    }

    /**
     * Formats error to be displayed for the prospect.
     */
    formatError(error) {
        const response = {
            errors: {
                errors: [
                    {
                        detail: error
                    }
                ]
            }
        };
        return response;
    }

    /**
     * Send CPID verification to the user.
     * @param {string} appointmentId
     * @param {boolean} disableButton
     * @returns
     */
    async sendCheckpointIdVerification(appointmentId, disableButton = true) {
        if (this.isSendCheckpointIdVerificationDisabled) {
            return;
        }

        const requestUrl = '/checkpointId/verify',
            newOptions = this.getAuthHeaders(),
            body = JSON.stringify({
                appointmentId
            }),
            httpMethod = 'POST';

        try {
            await this.ajax.apiRequest(
                requestUrl,
                newOptions,
                body,
                httpMethod
            );
        } catch (error) {
            const formattedError = this.formatError(error.errors[0].detail);
            return this.ajax.handleAjaxError(
                formattedError,
                'Unable to send id verification text.'
            );
        } finally {
            if (disableButton) {
                this.isSendCheckpointIdVerificationDisabled = true;

                setTimeout(
                    () => (this.isSendCheckpointIdVerificationDisabled = false),
                    30000
                );
            }
        }
    }
}
