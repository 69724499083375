import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { bool } from '@ember/object/computed';
import Appointment from './appointment';

/**
 * @classdesc
 * Base model for common data in models.Location.
 */
export default class BaseLocation extends Model {
    /**
     * Street address line 1 for Self-Guided Tours location.
     */
    @attr('string')
    declare street1: string;

    /**
     * Street address line 2 for Self-Guided Tours location.
     */
    @attr('string')
    declare street2: string;

    /**
     * City for Self-Guided Tours location address.
     */
    @attr('string')
    declare city: string;

    /**
     * State for Self-Guided Tours location address.
     */
    @attr('string')
    declare state: string;

    /**
     * Zip code for Self-Guided Tours location address.
     */
    @attr('string')
    declare zipCode: string;

    /**
     * URL of picture for Self-Guided Tours location.
     */
    @attr('string')
    declare pictureUrl: string;

    /**
     * Current user's appointment slot.
     */
    @belongsTo('appointment', { inverse: null })
    declare nextAppointment: Appointment;

    /**
     * Time zone of Self-Guided Tours location.
     */
    @attr('string')
    declare timeZone: string;

    /**
     * Abbreviation of the time zone of Self-Guided Tours location.
     */
    @attr('string')
    declare timeZoneAbbreviation: string;

    /**
     * Returns the appointment ID if the user has an appointment scheduled at this location.
     */
    @bool('nextAppointment.id')
    declare hasAppointment: boolean;

    /**
     * If the unit requires photo verification in order to tour
     */
    @attr('boolean')
    declare checkpointIdEnabled: boolean;

    /**
     * Whether this location has geofencing enabled.
     */
    @attr('boolean')
    declare geofenceEnabled: boolean;

    /**
     * If the user needs to be verified
     */
    @attr('boolean')
    declare reverificationRequired: boolean;

    /**
     * UserId
     */
    @attr('string')
    declare userId: string;

    /**
     * GroupId
     */
    @attr('string')
    declare groupId: string;

    /**
     * Location ID for CheckpointID
     */
    @attr('string')
    declare checkpointIDLocationID: string;

    /**
     * API Key for CheckpointID
     */
    @attr('string')
    declare checkpointIDApiKey: string;

    /**
     * Returns the street address of the location.
     */
    @computed('street1', 'street2')
    get streetAddress(): string {
        if (!this.street2) {
            return this.street1;
        }
        return `${this.street1}, ${this.street2}`;
    }

    /**
     * Returns the region portion of the location.
     */
    @computed('city', 'state', 'zipCode')
    get region(): string {
        return `${this.city}, ${this.state} ${this.zipCode}`;
    }

    /**
     * Returns the full address of the location.
     */
    @computed('streetAddress', 'region')
    get fullAddress(): string {
        return `${this.streetAddress}, ${this.region}`;
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
    export default interface ModelRegistry {
        'base-location': BaseLocation;
    }
}
