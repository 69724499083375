import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type { Registry as ServiceRegistry } from '@ember/service';
import LoginPendingBookingService from 'unattended-showing/services/login-pending-booking';
import Ajax from 'unattended-showing/services/ajax';
import SessionService from 'ember-simple-auth/services/session';

/**
 * @classdesc
 * Controller for login route.
 */
export default class LoginController extends Controller {
    @service declare session: SessionService;
    @service declare router: ServiceRegistry['router'];
    @service declare ajax: Ajax;
    @service
    declare loginPendingBooking: LoginPendingBookingService;
    declare model: { context: { id: string; systemGroupId: string } };

    /**
     * Stores messages from backend requests.
     */
    requestMessage = '';

    username?: string;
    password?: string;

    /**
     * Attempt to authenticate user.
     */
    @action async login(): Promise<void> {
        const { username, password, session } = this,
            { context } = this.model;

        try {
            // Try to authenticate user against backend.
            await session.authenticate(
                'authenticator:credentials',
                username!,
                password!,
                context.systemGroupId
            );
            this.router.transitionTo('context.profile', context.id);

            // Pending appointments are appointments the user tried to book before logging
            // in that should be automatically booked upon login.
            if (this.loginPendingBooking.hasPendingAppointments) {
                await this.loginPendingBooking.processPendingAppointments();
            }
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to login. Please try again later.'
            );
        }
    }

    /**
     * Transitions route to the signup page.
     */
    @action async signup(): Promise<void> {
        const { context } = this.model;

        try {
            this.router.transitionTo('context.sign-up-sgt3', context.id);
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to sign up. Please try again later.'
            );
        }
    }

    /**
     * Transitions route to the continue as guest page.
     */
    @action async continueAsGuest(): Promise<void> {
        try {
            /**
             * Do nothing. Temporarily left blank.
             * The guest checkout signup page will be created in POINT-4472.
             * Then we will transition to it.
             */
        } catch (response) {
            return this.ajax.handleAjaxError(
                response,
                'Unable to sign up. Please try again later.'
            );
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'context/login-sgt3': LoginController;
    }
}
